@import "../assets/Variables.scss";
.card_parent {
  width: calc(50vw + 8em);

  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50vw + 8em);
    height: 17.25vh;
    background-color: $green;
    cursor: pointer;
    text-decoration: none;
    transition: 0s;

    h2 {
      color: $blue;
      font-family: $font-texts;
      font-weight: 600;
    }

    &:hover {
      background-color: white;
      color: $blue;
    }
  }

  .sticker_left {
    width: 12.5vh;
    position: absolute;
    margin-top: -13.75vh;
    margin-left: -4vw;

    &:hover {
      animation: moveSticker 1s forwards;
    }
  }

  .sticker_left2 {
    width: 11vh;
    position: absolute;
    margin-top: -12vh;
    margin-left: -2vw;

    &:hover {
      animation: moveSticker 1s forwards;
    }
  }

  .sticker_right {
    width: 12.5vh;
    position: absolute;
    margin-top: -13vh;
    margin-left: calc(50vw + 6em);

    &:hover {
      animation: moveSticker 1s forwards;
    }
  }

  .sticker_right2 {
    width: 12.5vh;
    position: absolute;
    margin-top: -15.75vh;
    margin-left: calc(49vw + 6em);

    &:hover {
      animation: moveSticker 1s forwards;
    }
  }

  @keyframes moveSticker {
    0% {
      transform: rotate(0deg);
    }

    20% {
      transform: rotate(20deg);
    }

    40% {
      transform: rotate(-8deg);
    }

    60% {
      transform: rotate(6deg);
    }

    80% {
      transform: rotate(-2deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }
}

@media only screen and (min-width: 800px) {
  h2 {
    font-size: 5em;
  }
}

@media only screen and (max-width: 800px) {
  .sticker_left,
  .sticker_left2,
  .sticker_right,
  .sticker_right2 {
    display: none;
  }

  h2 {
    font-size: 5em;
  }
}

@media only screen and (max-width: 750px) {
  .card {
    h2 {
      font-size: calc(26px + 2.8em);
    }
  }
}

@media only screen and (max-width: 550px) {
  .card {
    width: calc(50vw + 6em);
    height: 12.5vh;
    h2 {
      font-size: calc(20px + 2em);
    }
  }
}

@media only screen and (max-width: 400px) {
  .card {
    width: calc(50vw + 6em);
    h2 {
      font-size: calc(10px + 2.2em);
    }
  }
}

@media only screen and (max-width: 300px) {
  .card {
    width: calc(50vw + 4em);
    h2 {
      font-size: calc(10px + 1.6em);
    }
  }
}
