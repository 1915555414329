$green: #3AFF49;
$blue: #020B55;

@import url('https://fonts.googleapis.com/css2?family=Syne:wght@500;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$font-titles: "Syne", sans-serif;
$font-texts: 'Roboto', sans-serif;

*{
   transition: 0.2s ease-in;
}