@import "./assets/Variables.scss";

.app {
  height: 100vh;
  margin: 0;
  padding: 0;
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2vh;

    .header {
      margin-top: 2vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: $green;

      min-height: 8vh;
      max-height: 8vh;

      p {
        padding: 0;
        margin: 0;
        font-family: $font-texts;
        font-weight: 200;
        font-size: 1em;
      }

      h1 {
        padding: 0;
        margin: 0;
        margin-top: -10px;
        font-family: $font-titles;
      }
    }

    .card_group {
      height: 75vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2vh;
      margin: 0;
      padding: 0;
    }

    .footer{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 10vh;
      max-height: 10vh;
      gap: 1vh;
    }

    .icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20%;

      a {
        cursor: pointer;
      }

      img {
        height: calc(12px + 1rem);
        transition: 0s;

        &:hover {
          filter: brightness(0) invert(1);
        }
      }
    }

    .contact {
      text-decoration: none;
      
      p {
        transition: 0s;
        color: $green;
        font-family: $font-texts;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 3px;
        margin: 0;
        padding: 0;

        &:hover{
          color: white;
        }
      }
    }
  }
}


@media only screen and (max-width: 300px) {
  .header {

    p {
      font-size: 0.8em;
    }

    h1 {
      font-size: 1.4em;
    }
  }
}